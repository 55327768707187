import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Navbar from "root/components/Navbar";
import Hero from "root/sections/HomeHero";
import HomeTestimonials from "root/sections/Testimonials";
import HomeValuePropositions from "root/sections/HomeValuePropositions";
import ProjectsSection from "root/sections/ProjectsSection";
import HomeInstitutions from "root/sections/HomeInstitutions";
import Footer from "root/components/Footer";
import Apoios from "root/components/Apoios";
import { useTranslation } from "react-i18next";
import ShuffleArray from "../../components/ShuffleArray";

/* eslint-disable id-length */
const IndexPage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  const testimonials = ShuffleArray(t("testimonials").testimonials);

  const data = useStaticQuery(graphql`
    query {
      testimonial: file(relativePath: { eq: "testimonial.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO title="Sioslife" />
      <Layout>
        <Navbar currentPage="home" />
        <Hero translations={t("home.hero")} />
        <HomeValuePropositions translations={t("home.values")} />
        <ProjectsSection translations={t("home.projects")} />
        <HomeTestimonials
          image={data}
          color="blue"
          videoUrl="https://www.youtube.com/embed/fLB4i9SpJN8"
          translations={t("testimonials")}
          testimonials={testimonials}
        />
        <HomeInstitutions translations={t("home.institutions")} />
        <Footer />
        <Apoios />
      </Layout>
    </div>
  );
};

export default IndexPage;
